import React from "react";
import { groupedwave, homeimage, rightarrow, windfan } from "../../assets";
import { Link } from "react-scroll";

function Home() {
  return (
    <div
      className='flex flex-col min-h-screen bg-cover bg-gradient-to-b from-gradient1 via-gradient3 to-gradient2'
      id='home'
    >
      <div className='flex-grow p-3'>
        <div className='flex items-center justify-start p-8'>
          <div>
            <div className='absolute z-10 bottom-1/2'>
              <h1 className='2xl:text-4xl'>
                Sustainablity | Eco-Friendliness | Empowerment
              </h1>
              <h1 className='text-4xl font-bold 2xl:text-6xl'>
                Empowering the
              </h1>
              <h1 className='text-4xl 2xl:text-6xl text-green font-bold mb-2'>
                Green Energy{" "}
                <span className='text-4xl 2xl:text-6xl text-orange font-bold'>
                  Value Chain
                </span>
              </h1>
              <h1 className='2xl:text-4xl'>
                A Marketplace Connecting Farmers, Processors, and Consumers
              </h1>
            </div>
            <div className='flex items-center absolute z-10 bottom-1/3'>
              <h1 className='text-2xl 2xl:text-6xl text-green font-bold md:ml-24'>
                Join Our Green{" "}
                <span className='text-2xl 2xl:text-6xl text-orange font-bold'>
                  Revolution
                </span>
              </h1>
              <div className='mr-12 md:mr-0'>
                <Link to='about' spy={true} smooth={true} offset={-50}>
                  <img
                    src={rightarrow}
                    alt=''
                    className=' mr-6 md:mr-0 md:ml-4 2xl:h-16 2xl:w-16  cursor-pointer hover:scale-95'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Your main content goes here */}
      </div>

      <footer className='relative'>
        {/* Background wave */}
        <img
          src={groupedwave}
          alt='Wave 2'
          className='absolute bottom-0 right-0 w-full'
        />
        <img
          src={windfan}
          alt='Windfan Image'
          className='absolute z-9 bottom-1/3 right-1/3 object-cover'
        />

        <img
          src={homeimage}
          alt='Your Image'
          className='absolute bottom-0 right-0 object-cover'
        />
      </footer>
    </div>
  );
}

export default Home;
