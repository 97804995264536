import { blog1, blog2, blog3, blogleft, blogright } from "../../assets";
import Card from "../../components/card";

import blogsData from "../../constants/blogs/blogs.json";

import React, { useState } from "react";

function Blogs() {
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 3;

  const handleNextClick = (itemsPerPage: number) => {
    if (startIndex + itemsPerPage < blogsData.length) {
      setStartIndex(startIndex + 1);
    }
  };
  const handlePreviousClick = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };
  return (
    <div>
      <div className="p-10" id="blogs">
        <div>
          <h1 className="font-inter text-lightgreen md:text-4xl text-2xl tracking-normal text-justify font-bold">
            Our Blog
          </h1>
          <h1 className="font-inter text-green md:text-2xl text-xl tracking-normal text-justify font-bold mb-3">
            Featured Articles
          </h1>
          <h1 className="font-inter text-green text-l 2xl:text-2xl">
            Explore our informative blog articles about agriculture,
            sustainability, and the green energy value chain
          </h1>
        </div>

        {/* blog cards */}
        <div className="flex justify-between items-center mt-5">
          {/* Show One card on the smaller screens */}
          <div className="w-full flex justify-center  md:hidden lg:hidden xl:hidden">
            <div className="w-full flex justify-between items-center">
              <img
                src={blogleft}
                alt=""
                className="cursor-pointer hover:scale-90 w-10 h-10 mr-3"
                onClick={handlePreviousClick}
              />
              <div className="flex justify-center">
                {blogsData
                  .slice(startIndex, startIndex + 1)
                  .map((item, index) => (
                    <Card
                      index={index}
                      img={require(`../../assets/applicationImages/${item.img}.png`)}
                      title={item.title}
                      explanation={item.description}
                    />
                  ))}
              </div>
              <img
                src={blogright}
                alt=""
                className="cursor-pointer hover:scale-90 w-10 h-10 ml-3"
                onClick={() => handleNextClick(1)}
              />
            </div>
          </div>

          {/* Show two crads ont he medium screens */}
          <div className="w-full flex justify-center hidden md:flex lg:hidden xl:hidden">
            <div className="w-full flex justify-between items-center">
              <img
                src={blogleft}
                alt=""
                className="cursor-pointer hover:scale-90"
                onClick={handlePreviousClick}
              />
              <div className="flex justify-center">
                {blogsData
                  .slice(startIndex, startIndex + 2)
                  .map((item, index) => (
                    <Card
                      index={index}
                      img={require(`../../assets/applicationImages/${item.img}.png`)}
                      title={item.title}
                      explanation={item.description}
                    />
                  ))}
              </div>

              <img
                src={blogright}
                alt=""
                className="cursor-pointer hover:scale-90"
                onClick={() => handleNextClick(2)}
              />
            </div>
          </div>
          {/* This is for the larger screen viwes */}
          <div className="w-full flex justify-center hidden sm:hidden md:hidden lg:flex xl:flex">
            <div className="w-full flex justify-evenly items-center">
              <img
                src={blogleft}
                alt=""
                className="cursor-pointer hover:scale-90"
                onClick={handlePreviousClick}
              />
              <div className="flex justify-center">
                {blogsData
                  .slice(startIndex, startIndex + 3)
                  .map((item, index) => (
                    <Card
                      index={index}
                      img={require(`../../assets/applicationImages/${item.img}.png`)}
                      title={item.title}
                      explanation={item.description}
                    />
                  ))}
              </div>

              <img
                src={blogright}
                alt=""
                className="cursor-pointer hover:scale-90"
                onClick={() => handleNextClick(3)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-centers">
        <div className="text-center bg-servbg border-solid border-t-2 border-b-2 border-r-2 border-green w-fit p-2 pl-4 mb-10 rounded-br-full rounded-tr-full">
          <h1 className="text-sm  font-bold sm:text-xl hover:scale-95">
            <a href="https://blog.farmloop.tech/" target="_blank">
              Read More Articles
            </a>
          </h1>
        </div>

        <div className="text-center bg-servbg border-solid border-t-2 border-b-2 border-l-2 border-green w-fit p-2 pl-4 mb-10 rounded-s-full">
          <h1 className="text-sm sm:text-xl font-bold hover:scale-95">
            <a href="https://blog.farmloop.tech/" target="_blank">
              Read More Articles
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
