import React from "react";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/service";
import Frameworks from "./pages/frameworks";
import Blogs from "./pages/blogs";
import Contact from "./pages/contact";
import Navbar from "./components/navbar";

function App() {
  return (
    <div className='App'>
      <Navbar />
      <Home />
      <About />
      <Services />
      <Frameworks />
      <Blogs />
      <Contact />
    </div>
  );
}

export default App;
