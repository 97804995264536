import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as DomLink } from "react-router-dom";

import { Logo } from "../../assets";
function Navbar() {
  const [open, setIsOpen] = useState(false);
  return (
    <div className="fixed z-50 w-full">
      <nav className="bg-green rounded-2xl m-3">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* <!-- Mobile menu button--> */}
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setIsOpen(!open)}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {/* <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          --> */}
                <svg
                  className="block h-6 w-6"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  color="white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                {/* <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          --> */}
                <svg
                  className="hidden h-6 w-6"
                  fill="white"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  className="hover:cursor-pointer"
                  aria-current="page"
                >
                  <img className="h-8 w-auto" src={Logo} alt="Your Company" />
                </Link>
              </div>
              <div
                className="flex items-center justify-center hidden sm:ml-6 sm:block"
                style={{ margin: "0 auto" }}
              >
                <div className="flex space-x-4">
                  {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    className="text-white rounded-md px-3 py-2 text-sm font-medium hover:underline hover:cursor-pointer hover:text-orange"
                    aria-current="page"
                  >
                    Home
                  </Link>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                    className="text-white rounded-md px-3 py-2 text-sm font-medium hover:underline hover:cursor-pointer hover:text-orange"
                  >
                    About
                  </Link>
                  <Link
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                    className="text-white rounded-md px-3 py-2 text-sm font-medium hover:underline hover:cursor-pointer hover:text-orange"
                  >
                    Services
                  </Link>
                  {/* <DomLink
                    target="_blank"
                    to={"https://blog.farmloop.tech/"}
                    onClick={() =>
                      window.open("https://blog.farmloop.tech/", "_blank")
                    }
                    className="text-white rounded-md px-3 py-2 text-sm font-medium hover:underline hover:cursor-pointer hover:text-orange"
                  >
                    Blogs
                  </DomLink> */}
                  <Link
                    to="blogs"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                    onClick={() => window.open("https://blog.farmloop.tech/")}
                    className="text-white rounded-md px-3 py-2 text-sm font-medium hover:underline hover:cursor-pointer hover:text-orange"
                  >
                    Blogs
                  </Link>
                </div>
              </div>
            </div>
            <div className="hidden sm:flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
              >
                <button
                  type="button"
                  className="relative text-white border-2 border-androidgreen rounded-md lg:pl-5 lg:pr-5 sm:pl-2 sm:pr-2 p-1 text-hover:text-white focus:outline-none focus:ring-1 focus:ring-androidgreen focus:ring-offset-1 focus:ring-offset-androidgreen"
                >
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        {open && (
          <div className="absolute z-10 sm:hidden" id="mobile-menu">
            <div className="space-y-1 px-2 pb-3 pt-2 rounded-l bg-green">
              {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className=" block rounded-md px-3 py-2 text-white font-medium hover:text-orange  hover:underline hover:cursor-pointer"
                aria-current="page"
                onClick={() => setIsOpen(false)}
              >
                Home
              </Link>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                className="hover:text-orange block rounded-md px-3 py-2 text-white font-medium hover:underline hover:cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                About
              </Link>
              <Link
                to="service"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="hover:text-orange block rounded-md px-3 py-2 text-white font-medium hover:underline hover:cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Services
              </Link>
              <Link
                to="blogs"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="hover:text-orange block rounded-md px-3 py-2 text-white font-medium hover:underline hover:cursor-pointer"
                onClick={() => {
                  setIsOpen(false);
                  window.open("https://blog.farmloop.tech/");
                }}
              >
                Blogs
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="hover:text-orange block rounded-md px-3 py-2 text-white font-medium hover:underline hover:cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
