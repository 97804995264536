import React from "react";
import { life, questiondot } from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { questionmark } from "../../assets";
function About() {
  return (
    <div id="about">
      <div className="p-5 lg:mr-5">
        <div>
          <h1 className="text-green text-5xl 2xl:text-8xl font-bold mt-5">
            About Us
          </h1>
          <p className="font-inter text-green text-xl 2xl:text-2xl leading-8 tracking-normal  mt-5">
            We are the enablers of a vibrant green energy value chain. Our
            platform brings together agri farmers, cattle farmers, agri waste
            processors, industries, commercials, and domestic consumers to
            transform waste into clean energy and sustainable solutions,
            creating a marketplace that redefines the future of energy.
          </p>
        </div>
        <div className="flex w-1/2 mt-10">
          <div className="w-full md:w-1/2 p-4">
            <h1 className="text-green text-4xl md:text-5xl 2xl:text-8xl font-bold">
              Our Values
            </h1>
          </div>
          <div className="border-l border-green w-0 md:w-1/12"></div>
          <div className="w-full md:w-1/2 p-4">
            <h1 className="text-green text-xl 2xl:text-4xl font-bold">
              Sustainability, Eco-Friendliness, Empowerment
            </h1>
          </div>
        </div>
      </div>
      <div>
        <LazyLoadImage src={life} alt="life" className="w-full" />
      </div>
      <div className="w-full">
        <div className="flex items-center mt-10 ml-10 mr-12 2xl:ml-36">
          <div>
            <img
              src={questionmark}
              alt=""
              className="hidden lg:inline-block mr-16 2xl:mr-20 2xl:h-96"
            />
          </div>
          <div>
            <h1
              className="font-inter text-orange text-5xl 2xl:text-6xl font-bold leading-79 tracking-normal"
              aria-controls=""
            >
              Why Choose Us <span className="lg:hidden inline-block">?</span>
            </h1>
            <h1
              className="font-inter
              text-green
              text-xl
              2xl:text-4xl
              leading-8
              tracking-normal
              mt-5"
            >
              Experience the transformative power of our Green AgriMarketplace,
              connecting every link in the green energy value chain
            </h1>
          </div>
        </div>
        <div className="w-full">
          <img
            src={questiondot}
            alt=""
            className="hidden lg:inline-block mt-5 w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
