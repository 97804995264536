import React, { useState } from "react";
import {
  dataicon,
  earthimg,
  energy,
  guidance,
  recycle,
  servicesbg,
} from "../../assets";

function Services() {
  const [waste, setWaste] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<boolean>(false);
  const [energyOpen, setEnergyOpen] = useState<boolean>(false);
  const [guidanceOpen, setGuidanceOpen] = useState<boolean>(false);

  const handleWasteClick = () => {
    setWaste(true);
  };
  return (
    <div
      id="service"
      className="bg-servbg flex flex-col h-fit bg-cover mt-10 p-10 "
    >
      <h1 className="text-4xl 2xl:text-8xl  text-green font-bold ">
        {" "}
        Our Services
      </h1>
      {/* for large screens */}
      <div className="hidden lg:flex xl:flex">
        <img src={servicesbg} alt="" className="w-full h-full" />
      </div>
      {/* For small, and medium screens */}
      <div className="block lg:hidden xl:hidden">
        <div className="flex flex-1 justify-center mt-4 mb-5">
          <h1 className="text-4xl text-white font-bold ">Our Ecosystem</h1>
        </div>
        <div className="flex flex-col gap-10 justify-between items-center">
          <div className="">
            {!waste ? (
              <div className="flex flex-1 justify-center p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full transition-opacity border-2 border-green animate-slideright">
                <div
                  className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                  onClick={handleWasteClick}
                >
                  <img
                    src={recycle}
                    alt=""
                    className="w-12 h-12 md:w-20 md:h-20"
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full transition-opacity border-2 border-green animate-slideleft">
                  <div
                    className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                    onClick={() => {
                      setWaste(false);
                    }}
                    border-2
                    border-green
                  >
                    <img
                      src={recycle}
                      alt=""
                      className="w-12 h-12 md:w-20 md:h-20"
                    />
                  </div>
                </div>
                <div className="bg-lightgray rounded-2xl p-5 text-center animate-slideright">
                  <h1 className="text-lg md:text-xl lg:text-2xl font-bold">
                    Waste Listing
                  </h1>
                  <h1 className="text-sm md:text-base lg:text-lg">
                    Farmers can easily list their agricultural and cattle waste,
                    making the supply side of the green energy value chain
                    visible to potential users
                  </h1>
                </div>
              </div>
            )}
          </div>
          <div>
            {!energyOpen ? (
              <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideleft">
                <div
                  className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                  onClick={() => {
                    setEnergyOpen(true);
                  }}
                >
                  <img
                    src={dataicon}
                    alt=""
                    className="w-12 h-12 md:w-20 md:h-20"
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="bg-lightgray rounded-2xl p-5 text-center animate-slideleft">
                  <h1 className="text-lg md:text-xl lg:text-2xl font-bold">
                    Data Analytics
                  </h1>
                  <h1 className="text-sm md:text-base lg:text-lg">
                    Our data-driven approach empowers industries and farmers
                    with insights to optimize their green energy initiatives.
                  </h1>
                </div>
                <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideright">
                  <div
                    className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                    onClick={() => {
                      setEnergyOpen(false);
                    }}
                  >
                    <img
                      src={dataicon}
                      alt=""
                      className="w-12 h-12 md:w-20 md:h-20"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="">
            {!analytics ? (
              <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideright">
                <div
                  className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                  onClick={() => {
                    setAnalytics(true);
                  }}
                >
                  <img
                    src={energy}
                    alt=""
                    className="w-10 h-10 md:w-20 md:h-20"
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideleft">
                  <div
                    className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                    onClick={() => {
                      setAnalytics(false);
                    }}
                  >
                    <img
                      src={energy}
                      alt=""
                      className="w-10 h-10 md:w-20 md:h-20"
                    />
                  </div>
                </div>
                <div className="bg-lightgray rounded-2xl p-5 text-center animate-slideright">
                  <h1 className="text-lg md:text-xl lg:text-2xl font-bold">
                    BioGreen Products
                  </h1>
                  <h1 className="text-sm md:text-base lg:text-lg">
                    We connect agri waste processors, enabling them to list
                    sustainable products like biofuels and biofertilizers. We
                    facilitate them with procurement of waste from farmers and
                    the sale of these BioGreen Products to industries,
                    commercials, domestic consumers, and farmers through our
                    platform.
                  </h1>
                </div>
              </div>
            )}
          </div>
          <div className="">
            {!guidanceOpen ? (
              <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideleft">
                <div
                  className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                  onClick={() => {
                    setGuidanceOpen(true);
                  }}
                >
                  <img
                    src={guidance}
                    alt=""
                    className="w-10 h-10 md:w-20 md:h-20"
                  />
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="bg-lightgray rounded-2xl p-5 text-center animate-slideleft">
                  <h1 className="text-lg md:text-xl lg:text-2xl font-bold">
                    Expert Consultation
                  </h1>
                  <h1 className="text-sm md:text-base lg:text-lg">
                    We provide subject-matter experts a platform to provide
                    quality consultancy to farmers, processors, and consumers to
                    make profitable decisions.
                  </h1>
                </div>
                <div className="p-4 bg-lightgray w-24 h-24 md:w-40 md:h-40 rounded-full border-2 border-green animate-slideright">
                  <div
                    className="bg-lightgray w-16 h-16 md:w-32 md:h-32 rounded-full shadow-lg flex flex-1 justify-center items-center hover:scale-95 cursor-pointer"
                    onClick={() => {
                      setGuidanceOpen(false);
                    }}
                  >
                    <img
                      src={guidance}
                      alt=""
                      className="w-10 h-10 md:w-20 md:h-20"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
