import React from "react";
import { connectarrow, harmimg } from "../../assets";
import frameworks from "../../constants/frameworks/frameworks.json";
function Frameworks() {
  return (
    <div className='flex flex-1 flex-col'>
      <div className='bg-blogbg h-fit bg-cover p-10'>
        <div className='text-center'>
          <h1 className='text-2xl md:text-4xl 2xl:text-6xl text-green font-bold'>
            Harmonizing Growth: A Sustainable Supply Chain
          </h1>
        </div>
        <div className='flex flex-col sm:flex-row justify-center items-center'>
          <img src={harmimg} alt='' className=' w-72 h-72 sm:mr-10' />
          <div className='w-full  sm:w-1/2'>
            <h1 className='font-inter text-green text-xl tracking-normal lg:text-2xl'>
              Farmloop is committed to creating a circular economy where waste
              is seen as a valuable resource. Our mission is to inter-connect
              farm waste producers, waste processors, industries, commercials,
              and households seeking a sustainable future using organic
              solutions.
            </h1>
          </div>
        </div>
      </div>
      <div className='h-fit p-10'>
        <div>
          <h1 className='font-inter text-lightgreen md:text-4xl text-2xl tracking-normal text-justify font-bold'>
            Our Frameworks For
          </h1>
          <h1 className='font-inter text-green  text-xl md:text-4xl tracking-normal text-justify font-bold'>
            Data-Driven Sustainability
          </h1>
          <h1 className='font-inter text-green text-l 2xl:text-2xl'>
            Our platform offers state-of-the-art data analytics to support
            industries and farmers
          </h1>
        </div>
        {/* For large Screen View */}
        <div className=' p-10 justify-center items-center hidden sm:hidden md:hidden lg:flex xl:flex'>
          {frameworks.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className=' flex items-center p-3 bg-servbg rounded-tl-3xl rounded-br-3xl h-24 w-40 2xl:h-36 2xl:w-56 text-center'
                  style={{
                    borderRadius: "3rem 0rem 3rem 0rem",
                    border: "1px solid",
                  }}
                >
                  <h1 className='text-xs 2xl:text-xl font-bold'>{item.text}</h1>
                </div>
                {index < frameworks.length - 1 && (
                  <img src={connectarrow} alt='' />
                )}
              </>
            );
          })}
        </div>

        {/* For mobile and medium screen view */}
        <div className='flex flex-col p-10 justify-center items-center sm:flex md:flex lg:hidden xl:hidden'>
          {frameworks.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className=' flex items-center p-3 bg-servbg rounded-tl-3xl rounded-br-3xl h-24 w-40 text-center'
                  style={{
                    borderRadius: "3rem 0rem 3rem 0rem",
                    border: "1px solid",
                  }}
                >
                  <span className='text-xs font-bold'>{item.text}</span>
                </div>
                {index < frameworks.length - 1 && (
                  <div className='w-40 flex justify-center'>
                    <img
                      src={connectarrow}
                      alt=''
                      className='rotate-90 mt-5 mb-5 h-4'
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Frameworks;
