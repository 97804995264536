import React from "react";

function Card({
  index,
  img,
  title,
  explanation,
}: {
  index: number;
  img: any;
  title: string;
  explanation: string;
}) {
  return (
    <div
      key={index}
      className=" sm:w-60 h-fit p-3 sm:p-5 mb-5 sm:mr-5 mx-auto "
    >
      <img
        src={img}
        alt=""
        className="w-60 mx-auto mb-2 border-4 rounded-2xl border-green"
      />
      <div className="">
        <h1 className="text-orange text-sm sm:text-xl mb-2 hover:scale-95">
          <a href="https://blog.farmloop.tech/" target="_blank">
            {title}
          </a>
        </h1>
      </div>
      <h1 className="text-green text-xs sm:text-sm sm:text-md mb-2">
        {explanation}
      </h1>
      <h1 className="text-servbg text-sm ">
        <a href="https://blog.farmloop.tech/" target="_blank">
          read more
        </a>
      </h1>
    </div>
  );
}

export default Card;
