import React from "react";
import {
  Logo,
  contactquestion,
  fbicon,
  footerfan,
  linkedinicon,
  signuparrow,
  youtubeicon,
} from "../../assets";
import { Link } from "react-scroll";
import { Link as DomLink } from "react-router-dom";

import ContactForm from "../../components/ContactForm";
function Contact() {
  return (
    <div id="contact">
      <div className="min-h-screen p-2">
        <div className="flex justify-center">
          <div className="w-80">
            <img src={contactquestion} alt="" />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex items-center border-solid border-2 w-1/2 text-center border-green h-32 p-10 rounded-xl mt-5">
            <h1 className="text-green  md:text-2xl sm:text-lg lg:text-4xl  font-bold">
              Have questions ? or Want to Collabrate?
            </h1>
          </div>
        </div>
        <ContactForm />
        <div>
          <div className="ml-10">
            <img src={footerfan} alt="" />
          </div>
        </div>
      </div>
      <div className="bg-green p-5 -mt-2">
        <div className="mt-10">
          <div className="flex flex-col md:flex-row justify-around items-center">
            <div className="flex flex-col">
              <img src={Logo} alt="" />
              <div className="flex justify-between items-center h-10 bg-white rounded-3xl p-3 mt-5">
                <h1 className="text-green text-sm ">
                  Sign up for your daily dose of news
                </h1>
                <img src={signuparrow} alt="" className="h-8" />
              </div>
            </div>
            <div className="flex flex-row mt-5 mb-5 md:flex-col gap-4 md:gap-0">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="text-white rounded-md text-lg md:text-xl font-medium hover:underline hover:cursor-pointer hover:text-orange"
                aria-current="page"
              >
                Home
              </Link>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                className="text-white rounded-md text-lg md:text-xl font-medium hover:underline hover:cursor-pointer hover:text-orange"
              >
                About
              </Link>
              <Link
                to="service"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="text-white rounded-md text-lg md:text-xl font-medium hover:underline hover:cursor-pointer hover:text-orange"
              >
                Services
              </Link>
              <Link
                to="blogs"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                onClick={() => {
                  window.open("https://blog.farmloop.tech/");
                }}
                className="text-white rounded-md text-lg md:text-xl font-medium hover:underline hover:cursor-pointer hover:text-orange "
              >
                Blogs
              </Link>
            </div>

            <div className="flex flex-col text-center md:text-left">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="text-white rounded-md  text-lg md:text-xl font-medium hover:underline hover:cursor-pointer hover:text-orange "
              >
                Contacts
              </Link>
              <h1 className="text-white text-lg">
                <DomLink
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:connect@farmloop.tech";
                    e.preventDefault();
                  }}
                >
                  Email:{" "}
                  <span className="text-white text-lg  hover:underline hover:cursor-pointer hover:text-blue-500">
                    connect@farmloop.tech
                  </span>
                </DomLink>
              </h1>
              {/* <h1 className="text-white text-lg">Phone: +123-456-7890</h1>
              <h1 className="text-white text-lg">
                Address: [Your Office Address]
              </h1> */}
            </div>
          </div>
          <div className="flex justify-between items-center mt-14 ml-10 mr-10">
            <div className=" text-center">
              <h1 className="text-white  mt-10">
                &copy; 2023 Farmloop. All Rights Reserved.
              </h1>
            </div>
            <div className="hidden md:flex flex-col ">
              <div className="flex">
                <a
                  href="https://www.facebook.com/profile.php?id=61555827202977&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-75 transition-opacity duration-300"
                >
                  <img src={fbicon} alt="" className="h-10 cursor-pointer" />
                </a>
                
                 <a
                  href="https://www.linkedin.com/company/farmloop-technologies"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-75 transition-opacity duration-300"
                >
                  <img src={linkedinicon} alt="" className="h-10 cursor-pointer" />
                </a>
                
                <a
                  href="https://www.youtube.com/@FarmloopTechnologies"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-75 transition-opacity duration-300"
                >
                  <img src={youtubeicon} alt="" className="h-10 cursor-pointer" />
                </a>
                
              </div>
              <div>
                <h1 className="text-white text-base">
                  Privacy Policy | Terms of Use
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
